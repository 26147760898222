import request from "./request.js";
import baseUrl from "./config.js"
// import qs from "qs";

//提交
export function getRouters(params) {
	return request({
		url: baseUrl + "/spider/web/getRouters",
		method: "get",
		data: params
	});
}