import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		msgInfo: 0
	},
	mutations: {
		msgInfoChange: () => {
			this.state.msgInfo++;
		}
	},
	actions: {},
	modules: {}
})