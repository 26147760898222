import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'NetDisk',
		component: () => import('../views/NetDisk/NetDisk.vue') // 网盘
	},
	{
		path: '/Square',
		name: 'Square',
		component: () => import('../views/Square/Square.vue') // 广场
	},
	{
		path: '/Reference',
		component: () => import('../views/Reference/Reference.vue'), // 报价
		children: [{
			path: '',
			name: 'Reference',
			component: () => import('../views/Reference/mainPage/') // 报价
		}, {
			path: 'history',
			name: 'history',
			component: () => import('../views/Reference/history/history.vue') // 报价
		}, {
			path: 'ReferenceForm',
			name: 'ReferenceForm',
			component: () => import('../views/Reference/ReferenceForm/ReferenceForm.vue') // 报价
		}, ]
	},
	{
		path: '/Chart',
		name: 'Chart',
		component: () => import('../views/Chart/Chart.vue') // 大仙
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login.vue') // 登录
	},
	{
		path: '/PerCenter',
		name: 'PerCenter',
		component: () => import('../views/PerCenter/PerCenter.vue'), // 个人中心
	},
	{
		path: '/Staging',
		name: 'Staging',
		component: () => import('../views/staging/staging.vue') // 工作台
	},
	{
		path: '/Notice',
		name: 'Notice',
		component: () => import('../views/PerCenter/Notice/Notice.vue') // 公告与通知
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: () => import('../views/PerCenter/ContactUs/ContactUs.vue') // 联系我们
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import('@/views/not-found/404.vue') // 404
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/notfound'
	}
]

const router = new VueRouter({
	mode: 'history',
	routes,
	linkActiveClass: 'active'
})

// router.afterEach((to) => {
// 	if (to.meta.title) {
// 		window.document.title = "千数万数 - " + to.meta.title;
// 	} else {
// 		window.document.title = "千数万数 - 数据可视化查询服务";
// 	}
// })

router.beforeEach((to, from, next) => {
	// 让页面回到顶部
	document.documentElement.scrollTop = 0
	// 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
	if (to.name != 'nopath') {
		next()
	}

})

export default router