<template>
	<div id="navList">
		<i class="bac"></i>
		<i class="bac2"></i>
		<!-- 盘思洞logo										 -->
		<div class="logo">
			<el-image class="logoImg" fit="cover" :src="require('../assets/images/logo.svg')"></el-image>

		</div>
		<!-- <router-link tag="li" exact to="/"><a>首页</a></router-link> -->
		<div class="routers">
			<template v-for="item in routerList">
				<router-link v-if="!item.children" class="first" exact :key="item.name" tag="div"
					:to="{name:item.name}">
					<div class="tit">
						<p>
							<i :class="`icon ${item.name}`"></i>
							{{item.meta.title}}
						</p>
						<i class="opt"></i>
					</div>
				</router-link>
				<!-- <div v-else :key="item.name" :class="activeRoute == 'gognzuotai'?'first sub':'first'" -->
				<div v-else :key="item.name" class="first sub" @click="activeRoute = 'gognzuotai'">
					<div class="tit">
						<p>
							<i :class="`icon ${item.name}`"></i>
							{{item.meta.title}}
						</p>
						<i class="icon el-icon-arrow-right"></i>
					</div>
					<ul>
						<router-link tag="li" v-for="sub in item.children" exact :key="sub.name" :to="{name:sub.name}">
							<i v-if="sub.name=='Staging'" class="icon shangchuantupian"></i>
							<i v-if="sub.name=='PerCenter'" class="el-icon-setting"></i>
							{{sub.meta.title}}
						</router-link>
					</ul>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import {
		getRouters
	} from '@/request/apiNav.js';
	export default {
		name: 'navList',
		data: () => {
			return {
				active: 'NetDisk',
				routerList: [],
				activeRoute: ''
			}
		},
		created() {
			this.getRoutersFun();

		},
		watch: {
			$route: {
				handler(val) {
					this.reViewNav(val);
				},
				deep: true, // 深度观察监听
				immediate: true
			}
		},
		methods: {
			reViewNav(path) {
				let route = path.name;
				if (route == 'Staging') {
					route = 'gognzuotai'
				} else if (route == 'PerCenter') {
					route = 'gognzuotai'
				}
				this.activeRoute = route;
				// console.log(route); //新路由信息
			},

			getRoutersFun() {
				getRouters().then(res => {
					if (res.code == 200) {
						let data = res.data;
						// data.map(item => {
						// 	item.title = item.meta.title
						// })
						this.routerList = data;
					}
				}).catch(err => {
					console.log(err)
				})
			},
		}
	}
</script>

<style scoped lang="less">
	#navList {
		width: 260px;
		background-color: #ccc;
		height: 100vh;
		background-image: linear-gradient(0deg, #625bff, #c548ab);
		position: relative;
		overflow: hidden;

		* {
			z-index: 1;
		}

		.logo {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			height: 200px;
			font-size: 20px;
			color: #fff;
			font-weight: bold;

			.logoImg {
				width: auto;
				height: 50px;
				// border-radius: 10px;
				overflow: hidden;
				margin-right: 20px;

			}
		}

		.routers {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;

			* {
				transition: all .3s;
			}

			.first {
				margin-bottom: 30px;
				color: #fff;
				font-size: 18px;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.tit {
					height: 56px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					padding: 0 30px;
					cursor: pointer;

					* {
						cursor: pointer;
					}

					p {
						font-size: 18px;
					}
				}

				ul {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 0;
					overflow: hidden;

					li {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 18px;
						margin-top: 30px;
						width: 100%;
						height: 56px;
						cursor: pointer;

						i {
							margin-right: 4px;
						}

						* {
							cursor: pointer;
						}
					}

					li.active {
						background: rgba(104, 46, 220, 0.3);
					}
				}
			}

			.first.active {
				.tit {
					background: rgba(104, 46, 220, 0.3);
				}
			}

			.sub {
				.tit {
					.icon {
						transform: rotate(90deg);
					}
				}

				ul {
					height: 172px;
				}
			}

		}

		.bac {
			position: absolute;
			width: 500px;
			height: 500px;
			box-sizing: content-box;
			top: 0;
			right: 0;
			transform: translate(120px, -200px);
			border: 200px solid rgba(255, 255, 255, .1);
			border-radius: 50%;
			z-index: 0;
		}

		.bac2 {
			position: absolute;
			width: 500px;
			height: 500px;
			background-color: rgba(255, 255, 255, .1);
			left: 0;
			bottom: 0;
			border-top-left-radius: 250px;
			border-top-right-radius: 250px;
			transform: rotate(-45deg) translate(-350px, 100px);
			z-index: 0;
		}
	}
</style>