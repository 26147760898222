import request from "./request.js";
import baseUrl from "./config.js"

// 机构列表查询
export function mechanismList(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/deptTreeList",
		method: "get",
		data: params
	});
}

// 机构列表查询
export function deptManageTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/deptManageTreeList",
		method: "get",
		data: params
	});
}

// 获取用户信息
export function UserInfo() {
	return request({
		url: baseUrl + "/spider/web/getInfo",
		method: "get",
	});
}

// 修改密码
export function updatePwd(params) {
	return request({
		url: baseUrl + "/spider/web/updatePwd",
		method: "get",
		params: params
	});
}

// 机构信息修改
export function mechanismUpdate(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/update",
		method: "post",
		data: params
	});
}

// 机构信息删除
export function mechanismDelete(params) {
	return request({
		url: baseUrl + `/spider/web/mechanism/delete/${params}`,
		method: "post",
	});
}

// 新增机构、部门
export function mechanismAdd(params) {
	return request({
		url: baseUrl + "/spider/web/mechanism/add",
		method: "post",
		data: params
	});
}

// 获取项目列表
export function itemList(params) {
	return request({
		url: baseUrl + "/spider/web/item/getMyItem",
		method: "get",
		params: params
	});
}

// 新增项目
export function addItem(params) {
	return request({
		url: baseUrl + "/spider/web/item/addItem",
		method: "post",
		data: params
	});
}

// 修改项目
export function updateItem(params) {
	return request({
		url: baseUrl + "/spider/web/item/updateItem",
		method: "put",
		data: params
	});
}

// 获取报价明细
export function deptTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/detailed/deptTreeList",
		method: "get",
		params: params
	});
}

// 获取报价明细
export function detailedTreeList(params) {
	return request({
		url: baseUrl + "/spider/web/detailed/deptManageTreeList",
		method: "get",
		params: params
	});
}

// 新增报价明细
export function detailedAdd(params) {
	return request({
		url: baseUrl + "/spider/web/detailed/add",
		method: "post",
		data: params
	});
}

// 修改报价明细
export function detailedUp(params) {
	return request({
		url: baseUrl + "/spider/web/detailed/update",
		method: "post",
		data: params
	});
}

// 删除报价明细
export function detailedRemove(id) {
	return request({
		url: baseUrl + `/spider/web/detailed/remove/${id}`,
		method: "post",
	});
}

// 获取消息列表
export function getMyMessage(params) {
	return request({
		url: baseUrl + "/spider/web/message/getMyMessage",
		method: "get",
		params: params
	});
}

// 修改消息状态
export function updateStatus(params) {
	return request({
		url: baseUrl + "/spider/web/message/updateStatus",
		method: "post",
		data: params
	});
}

// 全部已读
export function updateAllStatus(params) {
	return request({
		url: baseUrl + "/spider/web/message/updateAllStatus",
		method: "post",
		data: params
	});
}

// 查询自己上传的网盘文件
export function manageList(params) {
	return request({
		url: baseUrl + "/spider/web/fileNetdisk/manageList",
		method: "get",
		params: params
	});
}

// 删除已上传文档
export function fileNetdiskRemove(params) {
	return request({
		url: baseUrl + `/spider/web/fileNetdisk/remove/${params}`,
		method: "post",
	});
}