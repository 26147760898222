<template>
	<div id="navTop">
		<!-- 顶部导航 -->
		<div class="navtitle" v-if="userInfo">{{userInfo.dept.deptName}}</div>
		<div class="userBox" v-if="userInfo">
			<div class="info">
				<el-popover placement="bottom" trigger="hover" width="500" popper-class="infoPopover">
					<el-badge :is-dot="MessageList.length > 0" class="item" slot="reference">
						<i class="el-icon-message-solid"></i>
					</el-badge>

					<div class="infoList">
						<div class="none" v-if="!MessageList[0]">
							<img src="../assets/images/notInfo.png" alt="">
							<span>没有新消息</span>
						</div>
						<div class="item" v-for="item in MessageList" :key='item.id'
							@click="handleCommand('Notice',item.id)">
							<el-image :src="item.userAvatar" fit="cover" v-if="item.messageType != 'PUBLIC'">
								<div slot="error" class="image-slot">
									<i class="el-icon-user-solid"></i>
								</div>
							</el-image>
							<div class="infoCont">
								<div class="tit"><b>{{item.messageType=='PUBLIC'?"公告":item.userName}}</b>{{item.title}}
								</div>
								<div class="con">{{item.message}}</div>
								<div class="time">2023-04-18 16:04:39</div>
							</div>
						</div>
						<div class="options">
							<div class="readAll" @click="readAll()" v-if="MessageList[0]">
								全部已读
							</div>
							<el-divider direction="vertical" v-if="MessageList[0]"></el-divider>
							<div class="loadMore" @click="handleCommand('Notice')">
								查看历史消息
							</div>
						</div>
					</div>
				</el-popover>
			</div>
			<el-divider direction="vertical"></el-divider>

			<div class="user">
				欢迎您！ {{userInfo.nickName}}
				<el-dropdown @command="handleCommand" trigger="click">
					<el-image class="userface" :src="userInfo.avatar" fit="cover">
						<div slot="error" class="image-slot">
							<i class="el-icon-user-solid"></i>
						</div>
					</el-image>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="1" icon="el-icon-user">个人中心</el-dropdown-item>
						<el-dropdown-item command="cantact" icon="el-icon-paperclip">联系我们</el-dropdown-item>
						<el-dropdown-item command="cantact" icon="el-icon-data-board">建议反馈</el-dropdown-item>
						<el-dropdown-item command="logOut" icon="el-icon-switch-button">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		UserInfo, // 获取用户信息 
		getMyMessage, // 或许消息列表
		updateAllStatus // 消息全部已读
	} from '@/request/apiPerCenter.js';
	export default {
		name: 'navTop',
		components: {},
		data() {
			return {
				userInfo: '',
				MessageList: []
			}
		},
		created() {
			this.getUserInfo();
			this.getMyMessageList();
		},
		mounted() {},
		watch: {
			"$store.state.msgInfo": {
				immediate: true, // 第一次的数据，也要当做是一种变化
				handler(val) {
					console.log('state', val)
					// 执行代码部分
					this.getMyMessageList();
				},
			},
		},
		methods: {
			getMyMessageList() {
				let data = {
					status: 'NOT-READ',
					pageSize: 3
				}
				getMyMessage(data).then(res => {
					if (res.code == 200) {
						this.MessageList = res.rows
					}
				})
			},

			// 页面加载
			reView() {
				// let path = this.$route.name;
				// console.log(path)
			},

			getUserInfo() {
				UserInfo().then(res => {
					if (res.code == 200) {
						this.userInfo = res.data.user;
					}
				}).catch(err => {
					console.log(err)
				})
			},

			readAll() {
				updateAllStatus().then(res => {
					if (res.code == 200) {
						this.getMyMessageList();
					}
				})
			},

			handleCommand(item, query) {
				// console.log(item)
				if (item == 1) {
					this.$router.push({
						name: 'PerCenter'
					});
				} else if (item == 2) {
					this.$router.push({
						name: 'departmentSetting'
					});
				} else if (item == 3) {
					this.$router.push({
						name: 'projectSetting'
					});
				} else if (item == 'Notice') {
					this.$router.push({
						name: 'Notice',
						query: {
							id: query
						}
					});
				} else if (item == 'cantact') {
					this.$alert(`如果您需要产品方面的协助<br/>请联系我们的管理员<br/>电话号：18631886228`, '联系我们', {
						confirmButtonText: '确定',
						type: 'info',
						dangerouslyUseHTMLString: true,
						closeOnClickModal: true
					});
				} else if (item == 'logOut') {
					// console.log('退出登录');
					this.$alert('您要退出登录么?', '确认', {
						confirmButtonText: '确定',
						type: 'warning',
						dangerouslyUseHTMLString: true,
						closeOnClickModal: true
					}).then(() => {
						localStorage.clear()
						this.$router.push({
							name: 'login'
						})
					})

				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.infoPopover {
		padding: 0 !important;

		.infoList {
			display: flex;
			flex-direction: column;

			.none {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #f4f4f4;

				img {
					margin: 20px 0 0;
					width: 170px;
				}

				span {
					font-size: 14px;
					color: #ccc;
					margin-bottom: 20px;
				}
			}

			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px 0;
				border-bottom: 1px solid #f4f4f4;

				&:last-child {
					margin-bottom: 0;
				}

				* {
					cursor: pointer;
				}

				.el-image {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f4f4f4;
					overflow: hidden;
					margin-right: 15px;
				}

				.infoCont {
					width: 300px;
					flex: 1;

					.tit {
						font-size: 14px;
						color: #181B1A;

						b {
							margin-right: 6px;
						}
					}

					.con {
						font-size: 14px;
						margin-top: 4px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					.time {
						font-size: 12px;
						margin-top: 4px;
					}
				}
			}

			.options {
				display: flex;
				flex-direction: row;
				align-items: center;
				border-bottom: 1px solid #f4f4f4;

				.readAll,
				.loadMore {
					flex: 1;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 14px;
					font-size: 14px;
					color: #682EDC;
					cursor: pointer;
				}
			}
		}
	}

	#navTop {
		height: 100px;
		padding: 0 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-shadow: 0 0 10px rgba(24, 27, 26, 0.1);

		.navtitle {
			font-size: 26px;
			color: #181B1A;
		}

		.userBox {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.el-divider {
				margin: 0 24px;
				height: 26px;
			}

			.info {


				/deep/.el-badge {
					font-size: 16px;
				}



			}

			.user {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: rgba(24, 27, 26, 0.8);
				font-size: 14px;
				cursor: pointer;

				* {
					cursor: pointer;
				}


				/deep/.userface {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f3f3f3;
					overflow: hidden;
					margin-left: 20px;

					.image-slot {
						font-size: 30px;

						i {
							color: #fefefe
						}
					}
				}
			}
		}
	}
</style>