import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../theme/index.css';

Vue.use(ElementUI);

import waterfall from 'vue-waterfall2'
Vue.use(waterfall)


import axios from 'axios' // 导入 axios
import VueAxios from 'vue-axios' // 导入 vue-axios
Vue.use(VueAxios, axios);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')