<template>
	<el-container id="app">
		<el-container>
			<el-aside width="260px" v-if='!navHide'>
				<navList />
			</el-aside>
			<el-container>
				<el-main>
					<navTop v-if='!navHide'></navTop>
					<router-view />
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>

<style lang="less">
	@import url('./assets/css/reset.css');
	@import url('./assets/icon/iconfont.css');

	#app {
		height: 100vh;
		min-width: 1440px;
		overflow: hidden;

		.el-main {
			padding: 0;
			overflow-y: scroll;
		}
	}
</style>

<script>
	import navList from './components/navList.vue'
	import navTop from './components/navTop.vue'
	export default {
		data() {
			return {
				navHide: false
			}
		},
		components: {
			navList,
			navTop
		},
		watch: {
			$route: {
				deep: true,
				immediate: true,
				handler(to /*, from*/ ) {
					// console.log(from.path) // 从哪来
					// console.log(to) // 到哪去
					if (to.path == '/login') {
						this.navHide = true;
					} else {
						this.navHide = false;
					}
				}

			}
		},
		// data() {
		// 	return {}
		// },
		created() {
			var token = localStorage.getItem('headToken')
			// var timeOut = localStorage.getItem('timeOut')
			if (!token) {
				this.$router.push({
					name: 'login'
				})
			}

		},
		// mounted() {},
		methods: {
			gettoken() {}
		}
	}
</script>